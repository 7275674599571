import React from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import { Link }  from 'gatsby'
import styled from 'styled-components'

import scssVars from '../scss-variables'

const StyledContainer = styled(Container)`
  background: center / cover no-repeat url('https://leatherspa.imgix.net/Ready-To-Start_Mobile@3x.png?fm=jpg');
  height: 278px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const DesktopContainer = styled(Container)`
  text-align: center;
  .ready-box {
    position: relative;
    top: 25%;
  }
`

const StyledTitle = styled.h3`
  font-size: 22px;
  line-height: 26.4px;
  font-weight: 700;
  color: ${scssVars.lightGray};
  margin-bottom: 1rem;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    color: ${scssVars.darkBrown}
  }
`

const StyledDescription = styled.p`
  font-family: 'Montserrat', Book;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.8px;
  font-weight: 100;
  color: ${scssVars.lightGray};
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    color: ${scssVars.darkBrown};
    margin: 0 auto;
    width: 65%;
  }
`

const StyledImg = styled.img`
  width: 100%;
`

const StyledLink = styled(Link)`
  width: 60% !important;
  margin: 5% auto;
`

const StyledSection = styled.section`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-top: 5%;
    margin-bottom: 5%;
  }
`

const ReadyToStart = () => {
  return (
    <StyledSection>
      <div className="d-lg-none">
        <StyledContainer className="text-center">
          <StyledTitle>Ready For Your Service Estimate?</StyledTitle>
          <StyledDescription>
          Use our Repair Wizard to discover our full range of services, get a repair estimate and start a mail-in or local pick-up order online.
          </StyledDescription>
          <Link to="/start-a-repair" className="btn btn-primary">
            Start A Repair
          </Link>
        </StyledContainer>
      </div>
      <div className="d-none d-lg-block">
        <DesktopContainer>
          <Row>
            <Col lg={{ size: 6 }} className="bg-clr-cararra">
              <div className="ready-box">
                <StyledTitle>Ready For Your Service Estimate?</StyledTitle>
                <StyledDescription>
                  Use our Repair Wizard to discover our full range of services, get a repair estimate and start a mail-in or local pick-up order online.
                </StyledDescription>
                <StyledLink to="/start-a-repair" className="btn btn-primary">
                  Start A Repair
                </StyledLink>
              </div>
            </Col>
            <Col lg={{ size: 6 }} style={{ padding: 0 }}>
              <StyledImg src="https://leatherspa.imgix.net/Ready-To-Start_Banner-image@2x.png?fm=jpg" alt="ready to start" />
            </Col>
          </Row>
        </DesktopContainer>
      </div>
    </StyledSection>
  )
}

export default ReadyToStart
