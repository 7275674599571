import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import { Lead } from '~components/Typography'
import scssVars from '~components/scss-variables'
import HeroSection from '~components/layouts/marketing/HeroSection'
import CustomerService from '~components/CustomerServices/CustomerService'
import ReadyToStart from '~components/Shared/ReadyToStart'
import SEO from '~components/seo'

const StyledContainer = styled(Container)`
  max-width: 100%;
  @media only screen and (min-width: 992px) {
    max-width: 1140px;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1280px;
  }
`

const CustomerServiceHeading = styled.h2`
  font-size: 24px;
  line-height: 34px;
  color: ${scssVars.darkBrown};
  font-weight: 900;
  margin-top: 1rem;
`

const StyledPolicies = styled.p`
  font-family: 'Montserrat', Book;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 0.8px;
  line-height: 18px;
  color: ${scssVars.darkBrown};
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: 70%;
    margin: 20px auto;
  }
`

const StyledLink = styled(Link)`
  font-size: 14px;
  font-style: italic;
  text-decoration: underline !important;
  color: ${scssVars.orange};
`

const services = [{
  title: 'Mail-In For Repair',
  imgSrc: 'https://leatherspa.imgix.net/Mail-in_Service_mobile@3x.png?fit=scale&w=992',
  description: 'We accept mail-in repairs worldwide and even from our own backyard. Simply send us your items if you can’t bring them in-store.',
  path: '/customer-services/mail-in'
}, {
  title: 'Schedule A Pick-Up',
  imgSrc: 'https://leatherspa.imgix.net/Pick-up_Service_mobile@3x.png?fit=scale&w=992',
  description: 'We offer pick-up services to most of Manhattan, much of Brooklyn, and Long Island City, Queens. Try our new ON-DEMAND pick-up service!',
  path: '/customer-services/pick-up'
}, {
  title: 'Drop Off For Repair',
  imgSrc: 'https://leatherspa.imgix.net/Drop-off_Service_mobile@3x.png?fit=scale&w=992',
  description: 'Experience our superb customer service and leather expertise in person at one of our multiple NYC locations. Bring in your shoes or accessory for a repair evaluation by a trained specialist.',
  path: '/customer-services/drop-off'
}, {
  title: 'Virtual Repair Service',
  imgSrc: 'https://leatherspa.imgix.net/Check_Repair_Status_mobile@3x.png?fit=scale&w=992',
  description: 'We make it easy to get in touch even remotely: our specialists are available virtually via video chat to answer any question you might have about your leather accessories.',
  path: '/customer-services/virtual-repair'
}, {
  title: 'Delivery Service',
  imgSrc: 'https://leatherspa.imgix.net/Delivery_Service_mobile@3x.png?fit=scale&w=992',
  description: 'We offer delivery services to most of Manhattan, much of Brooklyn, and Long Island City, Queens. We can even ship your items back to you anywhere in the world.',
  path: '/customer-services/delivery'
}, {
  title: 'Pay & Check Status Online',
  imgSrc: 'https://leatherspa.imgix.net/Payment_Service_mobile@3x.png?fit=scale&w=992',
  description: 'Use our secure online platform to make a payment towards services or check service status online.',
  path: '/customer-services/payment'
}]

export default function CustomerServicesPage () {
  return (
    <Layout>
      <SEO
        title="Customer Services"
        pathname="/customer-services"
        description="Discover all the ways Leather Spa can make your repair and care needs easier"
      />
      <Main>
        <HeroSection title={'At Your Service'}
          mobileImg={'https://leatherspa.imgix.net/LS_At-Your-Service_Top-image@2x.jpg?fit=scale&w=992'}
          desktopImg={'https://leatherspa.imgix.net/LS_At-Your-Service_Top-image@2x.jpg?fit=scale&w=1360'}>
          <Lead>Discover all the ways Leather Spa can simplify the repair experience.</Lead>
          <p>In addition to offering the finest repair and care for all your leather accessories, Leather Spa is committed to providing a seamless experience for our clients, in person or online.</p>
        </HeroSection>
        <section className="py-0 pt-md-3 py-lg-5">
          <StyledContainer>
            <Row>
              {services.map((s, i) => (
                <Col lg={{ size: 4 }} key={i}>
                  <CustomerService
                    title={s.title}
                    img={s.imgSrc}
                    description={s.description}
                    path={s.path}
                  />
                </Col>
              ))}
            </Row>
          </StyledContainer>
        </section>
        <ReadyToStart/>
        <section className="text-center py-5 bg-clr-cararra">
          <Container>
            <CustomerServiceHeading>Operational Policies</CustomerServiceHeading>
            <StyledPolicies>
              Please make sure to review our Operating Policies.
            </StyledPolicies>
            <StyledLink to="/operational-policies">Read More</StyledLink>
          </Container>
        </section>
      </Main>
    </Layout>
  )
}
