import React, { useState } from 'react'
import { Link } from 'gatsby'
import {
  Collapse,
  CardBody,
  CardHeader,
  Card,
  CardImg

} from 'reactstrap'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import scssVars from '~components/scss-variables'
import minusSvg from '../../img/customer-services/Minus_sign.svg'
import plusSvg from '../../img/customer-services/Plus_sign.svg'

const StyledCard = styled(Card)`
  &:first-child {
    border-top: 1px solid ${scssVars.darkBrown} !important;
  }
`

const StyledCardHeader = styled(CardHeader)`
  background-color: ${scssVars.white} !important;
  button {
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    &, &:hover, &:focus, &:active {
      color: ${scssVars.darkBrown};
    }
  }
`

const StyledCardBody = styled(CardBody)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`

const CustomerServiceHeading = styled.h2`
  font-size: 21px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.0125em;
  color: ${scssVars.darkBrown};
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-style: italic;
`

const StyledCardImg = styled(CardImg)`
  width: auto !important;
  height: 225px;
  margin: 0 auto;
`

const CustomerService = ({ title, description, path, img }) => {
  const [collapse, toggle] = useState(false)
  return (
    <main>
      <div className="d-lg-none">
        <StyledCard>
          <StyledCardHeader className="py-1 px-2">
            <button className="btn text-left btn-link w-100" onClick={() => toggle(!collapse)}>
              {title}
              { collapse ? <img className="float-right" src={minusSvg} /> : <img className="float-right" src={plusSvg} /> }
            </button>
          </StyledCardHeader>
          <Collapse isOpen={collapse}>
            <CardBody className="text-left">
              <img className="w-100" alt="TODO" src={img} />
              <CustomerServiceHeading className="mt-2">
                {title}
              </CustomerServiceHeading>
              <p>{description}</p>
              <Link to={path} className="btn btn-primary">
                Read More
              </Link>
            </CardBody>
          </Collapse>
        </StyledCard>
      </div>
      <div className="d-none d-lg-block">
        <Card style={{ border: 'none' }}>
          <StyledCardImg top src={img} alt="Card image cap" />
          <StyledCardBody className="text-center">
            <CustomerServiceHeading>{title}</CustomerServiceHeading>
            <p>{description}</p>
            <StyledLink to={path}>Read More</StyledLink>
          </StyledCardBody>
        </Card>
      </div>
    </main>
  )
}

CustomerService.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  img: PropTypes.string
}

export default CustomerService
